var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("ck-text-input", {
        attrs: {
          value: _vm.name,
          id: "name",
          label: "Name",
          type: "text",
          error: _vm.errors.get("name")
        },
        on: {
          input: function($event) {
            return _vm.onInput("name", $event)
          }
        }
      }),
      _vm.loading
        ? _c("ck-loader")
        : _c("ck-select-input", {
            attrs: {
              value: _vm.parent_id,
              id: "parent_id",
              label: "Parent",
              options: _vm.taxonomyOptions,
              error: _vm.errors.get("parent_id")
            },
            on: {
              input: function($event) {
                return _vm.onInput("parent_id", $event)
              }
            }
          })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
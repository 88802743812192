var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "gov-width-container",
    [
      _c("vue-headful", {
        attrs: { title: _vm.appName + " - Add Taxonomy Category" }
      }),
      _c(
        "gov-back-link",
        { attrs: { to: { name: "admin-index-taxonomies" } } },
        [_vm._v("Back to taxonomy categories")]
      ),
      _c(
        "gov-main-wrapper",
        [
          _c(
            "gov-grid-row",
            [
              _c(
                "gov-grid-column",
                { attrs: { width: "one-half" } },
                [
                  _c(
                    "gov-heading",
                    { attrs: { size: "xl" } },
                    [
                      _c("gov-caption", { attrs: { size: "xl" } }, [
                        _vm._v("Taxonomies")
                      ]),
                      _vm._v("\n          Categories\n        ")
                    ],
                    1
                  ),
                  _c("gov-heading", { attrs: { size: "m" } }, [
                    _vm._v("Add category")
                  ]),
                  _c("gov-body", [
                    _vm._v(
                      "\n          From this page you can add the name of the taxonomy 'tags' on the\n          site and how they relate to each other. These should not be added\n          without reviewing wider impact on the site\n        "
                    )
                  ]),
                  _c("taxonomy-form", {
                    attrs: {
                      errors: _vm.form.$errors,
                      parent_id: _vm.form.parent_id,
                      name: _vm.form.name,
                      order: _vm.form.order
                    },
                    on: {
                      "update:parent_id": function($event) {
                        return _vm.$set(_vm.form, "parent_id", $event)
                      },
                      "update:name": function($event) {
                        return _vm.$set(_vm.form, "name", $event)
                      },
                      "update:order": function($event) {
                        return _vm.$set(_vm.form, "order", $event)
                      },
                      clear: function($event) {
                        return _vm.form.$errors.clear($event)
                      }
                    }
                  }),
                  _vm.form.$submitting
                    ? _c(
                        "gov-button",
                        { attrs: { disabled: "", type: "submit" } },
                        [_vm._v("Creating...")]
                      )
                    : _c(
                        "gov-button",
                        {
                          attrs: { type: "submit" },
                          on: { click: _vm.onSubmit }
                        },
                        [_vm._v("Create")]
                      ),
                  _vm.form.$errors.any() ? _c("ck-submit-error") : _vm._e()
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }